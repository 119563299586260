/* Mobile first */
*, ::before, ::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  width: 100%;
}
body {
  font-family: 'Poppins', 'Open Sans', sans-serif !important;
  font-weight: 500;
  background-color: rgb(33, 33, 33);
  color: rgb(0, 212, 255);
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  line-height: 1.5;
}
a, .blue-text {
  color: rgb(0, 212, 255);
}
p {
  color: rgb(188, 175, 175);
}
span {
  cursor: default;
}
header {
  background-color: rgb(33, 33, 33);
  padding: 0 50px;
  display: flex;
  align-content: center;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 100;
  transition: all 0.3s ease;
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 16px;
  margin: 0 0 20px 0;
  line-height: 1.1;
}
h2 {
  font-size: 50px;
  margin: 0 0 20px -3px;
  line-height: 1.1;
}
h3 {
  font-size: 16px;
  line-height: 1.5;
}
h4 {
  font-size: 24px;
  padding-bottom: 20px;
}
header.tall {
  height: 100px;
}
header.short {
  height: 70px;
  box-shadow: rgb(99, 97, 97) 0px 2px 20px -10px;
}
header.off {
  visibility: hidden;
  height: 0;
  opacity: 0;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#root {
  height: 100vh;
  width: 100%;
}
#root.blur > #container > * {
  filter: blur(6px) brightness(0.7);
  pointer-events: none;
  user-select: none;
}
button {
  color: rgb(0, 212, 255);
  background: none;
  border: none;
  padding: 0;
  font-size: inherit;
  font-weight: 500;
}
button:focus {outline:0;}
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: rgb(15, 15, 15);
  color: rgb(188, 175, 175);
  text-align: center;
  font-size: 14px;
  padding: 10px;
  flex-direction: column;
  -ms-flex-direction: column;
}
/* Nav */
#nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 11;
}
#nav a {
  text-decoration: none;
}
#nav-list {
  display: none;
}
#name-nav-btn {
  font-size: 18px;
}
#hamburger:hover, #hamburger-close:hover {
  cursor: pointer;
}
#hamburger, #hamburger-close {
  animation-name: hamburgerSpin;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.2s;
}
#nav-list li {
  display: inline-block;
  margin: 0 10px;
}
.selected-section {
  color: rgb(255, 255, 255);
}
.navbar-btn {
  transition-duration: 0.1s;
}
.navbar-btn:hover {
  cursor: pointer;
  color: rgb(255, 255, 255);
  transition-duration: 0.1s;
}
#mobile-menu {
  display: none;
  z-index: 10;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100vh;
  z-index: 10;
  transform: translateX(100vw);
  visibility: visible;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#mobile-menu.show {
  display: block;
  transform: translateX(0vw);
}
#hamburger-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50vw;
  height: 100%;
  position: relative;
  right: 0;
  margin-left: auto;
  background-color: rgb(43, 43, 43);
}
#hamburger-menu ul li {
  margin: 0 auto 10px;
}
#hamburger-menu ul li * {
  width: 100%;
  padding: 3px 20px 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}
#mobile-resume {
  border: 1px solid rgb(0, 212, 255);
  border-radius: 5px;
  padding: 10px 20px !important;
}
#mobile-resume:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgb(0, 212, 255);
}
#right-side {
  display: none;
}
#left-side-socials {
  display: none;
}
#footer-socials {
  display: block;
}
/* Main Section */
#main-section {
  padding: 0 50px;
  margin: 0 auto;
}
section {
  max-width: 1000px;
  padding: 100px 0;
  margin: 0 auto;
}
section#home {
  height: 100vh;
  padding: 20vh 0;
}
#intro-summary {
  color: rgb(221, 208, 208);  
}
/* About Section */
#comp-img {
  max-width: 300px;
  width: 100%;
  height: 100%;
  border: 1px transparent;
  border-radius: 10px;
}
#about-image {
  width: 40%;
  max-width: 300px;
  position:relative;
  margin: 60px auto 0;
}
#about-image:after {
  transition: transform 0.3s;
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 20px;
  left: 25px;
  z-index: -1;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(0, 212, 255);
  border-image: initial;
}
#about-image:hover:after {
  transform: translate(-10px, -10px);
}
.whoami-summary {
  margin: 0;
}
#skills {
  text-align: left;
  font-size: 14px;
}
.tech-list, #skills-title {
  color: #fff;
}
/* Jobs Section */
#jobs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#jobs-list {
  display: flex;
  overflow-x: scroll;
  margin-bottom: 30px;
  width: calc(100% + 100px);
  margin-left: -50px;
}
#jobs-list li:first-child {
  margin-left: 50px;
}
#jobs-list li:last-child {
  padding-right: 50px;
}
.job-option {
  border-bottom: 2px solid rgb(188, 175, 175);
  font-size: 16px;
  color: rgb(188, 175, 175, 0.5);
  padding: 0px 15px;
  transition-duration: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px; 
  height: 50px;
}
.job-option:hover {
  cursor: pointer;
  background-color: rgba(208, 197, 197, 0.2);
  transition-duration: 0.2s;
}
.job-option.selected-job {
  border-left-color: rgb(0, 212, 255);
  color: rgb(0, 212, 255);
  background-color: rgba(208, 197, 197, 0.2);
}
.job-detail {
  display: none;
  opacity: 0;
	animation-name: fadeOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-duration: 0.2s;
}
.job-detail.selected-job-detail {
  display: block;
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.2s;
}
.company-name {
  margin: 10px 0;
  font-size: 18px;
  color: rgb(0, 212, 255);
}
.job-position {
  margin: 10px 0 20px;
  font-size: 18px;
  color: rgb(255,255,255);
}
.job-duties {
  height: 50vh;
  overflow-y: auto;
}
.job-duties li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px; 
  color: rgb(188, 175, 175);
}
.job-duties li:before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: rgb(188, 175, 175);
  line-height: 20px;
}
/* Project Section */
#projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  position: relative;
  gap: 20px 20px;
}
.project {
  background-color: rgb(65, 66, 66);
  padding: 20px;
  border: 1px transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s;
}
.project:hover{
  transform: translateY(-10px);
}
.project-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project-title {
  color: rgb(221, 208, 208);
  font-size: 20px;
  margin-bottom: 0.25em;
}
.project-description {
  margin-bottom: 0;
}
.project-footer {
  margin-top: 2em;
}
.project-icons {
  display: flex;
}
.project-icons a {
  color: rgb(188, 175, 175);
  font-size: 20px;
}
.project-icons .project-url {
  padding-left: 20px;
}
.project-tool {
  display: inline-block;
  color: rgb(188, 175, 175);
  padding-right: 10px;
  font-size: 12px;
  cursor: default;
}
/* Footer Section */
#footer-social-list li {
  display: inline-block;
  padding-right: 10px;
}
#footer-social-list li:last-child {
  padding-right: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes hamburgerSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
@keyframes fadeOutOpacity {
  100% {
    opacity: 100;
  }
  0% {
    opacity: 0;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 24px;
    margin: 0 0 20px 0;
    line-height: 1.1;
  }
  h2 {
    font-size: 70px;
    margin: 0 0 20px -3px;
    line-height: 1.1;
  }
  h3 {
    font-size: 16px;
    line-height: 1.5;
  }
  h4 {
    font-size: 30px;
    padding-bottom: 60px;
  }
  #nav-list {
    display: block;
  }
  /* Nav Section */
  #hamburger {
    display: none;
  }
  /* Left Side */
  #left-side-socials {
    position: fixed;
    bottom: 0;
    left: 40px;
    width: 40px;
    display: block;
  }
  #social-list:after {
    content: "";
    background-color: rgb(0, 212, 255);
    width: 1px;
    height: 100px;
    display: block;
    margin: 0 auto;
  }
  #social-list li {
    padding: 10px;
    text-align: center;
    transition: transform 0.2s;
  }
  #social-list li:last-child {
    margin-bottom: 20px;
  }
  #social-list li:hover {
    transform: translateY(-3px);
  }
  #social-list li a:hover {
    color: rgb(255, 255, 255) !important;
  }
  /* Right Side */
  #right-side {
    position: fixed;
    bottom: 0;
    right: 40px;
    width: 40px;
    display: block;
  }
  #side-email {
    letter-spacing: 0.65px;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    text-decoration: none;
  }
  /* Main Section */
  #main-section {
    padding: 0 150px;
  }
  section {
    padding: 150px 0;
  }
  section#home {
    padding: 40vh 0;
  }
  section:not(#home) h4 {
    display: flex;
    align-content: center;
    white-space: nowrap;
    width: 100%;
  }
  #intro-summary {
    width: 60%;
  }
  /* About Section */
  #about-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse;
  }
  #about-content {
    width: 60%;
  }
  #about-image {
    margin: 0 60px 0 0;
  }
  #jobs-container {
    flex-direction: row;
  }
  #jobs-details-container {
    padding-left: 40px;
  }
  #jobs-list {
    display: block;
    margin-left: 0;
    width: max-content;
    overflow-x: unset;
  }
  #jobs-list li:first-child {
    margin-left: 0;
  }
  #jobs-list li:last-child {
    padding-right: 0;
  }
  .job-option {
    border-left: 2px solid rgb(188, 175, 175);
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: none;
    width: 100%;
    justify-content: left;
    min-width: unset;
  }
  .job-option:hover {
    padding-right: 10px;
    padding-left: 30px;
  }
  .job-duties {
    height: 40vh;
  }
  .project-description {
    font-size: 14px;
  }
  #footer-socials {
    display: none;
  }
}